/*
Buttons
 */

.centered-element {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.little-spaced-element {
    margin: 5px;
}

.spaced-element {
    margin: 15px;
}

.lot-spaced-element {
    margin: 25px;
}

.bottom-spaced-element {
    margin-bottom: 15px;
}

.element-with-sharp-corners {
    border-radius: 0;
}

.filter-input-group {
    margin: 15px;
    width: 350px;
}

/*
Table
 */

.table th {
    border-top: 2px solid #dee2e6;
}

.vertically-aligned-table-row td {
    vertical-align: middle;
}

/*
General
 */

.navbar {
    border-bottom: 1px solid lightgrey;
}

#access-control {
    margin-top: 25px;
    margin-bottom: 25px;
}

button:focus {
    outline:0;
}
